import styled from "styled-components";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import Banner05 from "../../assets/images/Banner05.png"


export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const FooterContainer = styled.div`
  background: #D0BCA0;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
	/* box-shadow: 1px -9px 5px -3px rgba(240,240,240,1); */
	position: relative;
	width: 100%;

  @media screen and (min-width: 992px) {
    padding: 2rem 0;
  }
`;

export const FooterList = styled.div`
  display: block;
  width: 100%;
  text-align: left;
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLower = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 1rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarLink = styled.a`
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold};
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 8px;
  font-size: 12px;
  cursor: pointer;

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled.a`
  width: 220px;
  display: flex;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    width: 180px;
    display: block;
    margin: unset;
  }

  @media screen and (min-width: 1440px) {
    width: 220px;
  }
  /* padding-top: 8px; */
  img {
    width: 220px;

    @media screen and (min-width: 992px) {
      width: 220px;
    }

    @media screen and (min-width: 1440px) {
      width: 220px;
    }
  }
`;


const config = {

  theBox_: {

    ...md("md", {
      borderLeft: '2px solid #178992',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      height: 150,
    }),
  },

  thePaymentIMG: {
    position: 'relative',
    width: '60%',
    marginBottom: '1rem',
    padding: '0 1rem',

    // ...md("md", {
    //   width: '60%',
    // }),

    "& img": {
      width: '80%',

      ...md("md", {
        width: '80%',
      }),

    }
  },

  theWordIMG: {
    position: 'relative',
    width: '60%',
    marginBottom: '1rem',
    padding: '0 1rem',

    "& img": {
      width: '100%',

      ...md("md", {
        width: '80%',
      }),
    }
  },

  thePaymentIMG_: {
    position: 'relative',
    width: '80%',
    marginBottom: '1rem',
    padding: '0 1rem',

    "& img": {
      width: '100%'
    }
  },

  thePaymentIMGAli_: {
    position: 'relative',
    width: '40%',
    marginBottom: '1rem',
    padding: '0 1rem',

    "& img": {
      width: '100%'
    }
  },

  theSocialIMG: {
    position: 'relative',
    paddingRight: '0.5rem',
    cursor: 'pointer',
    marginTop: '1rem',
    marginBottom: 'unset',

    ...md("xmd", {
      marginTop: 'unset',
      marginBottom: '1rem',
    }),


  },

  theWechatIMG: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',

    "& img": {
      width: '50%'
    }
  },

  theSocialTitle: {
    textAlign: "center",
    marginLeft: "unset",

    ...md("md", {
      marginLeft: "3rem"
    }),

  },

  theSocialPosition: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginLeft: '1.5rem',

    ...md("sm", {
      width: '80%',
      marginLeft: '7rem',
    }),

    ...md("md", {
      width: '100%',
      marginLeft: '2rem',

    }),

    "& img": {
      width: '60%',

      ...md("md", {
        width: '100%'
      }),
    }
  },

  theLiveButton: {
    width: '60px',
    height: '60px',
    bottom: '4%',
    right: '20px',
    position: 'fixed',
    zIndex: '1000',
    border: '0px',
    borderRadius: '50%',
    cursor: 'pointer',

    "& img": {
      width: '100%'
    }

  },

  theWechatIMG: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',

    "& img": {
      width: '50%'
    }
  },

  theShoppingCartButton: {
    width: '60px',
    height: '60px',
    bottom: '13%',
    right: '20px',
    position: 'fixed',
    zIndex: '1000',
    border: '0px',
    borderRadius: '50%',
    cursor: 'pointer',

    ...md("md", {
      bottom: '13%',
      right: '20px',
    }),

    "& img": {
      width: '100%'
    }

  },

  theFooterBanner: {
    position: "relative",
    backgroundImage: "url(" + Banner05 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "800px",

    ...md("md", {
      height: "432px",
    }),
  },

  theFooterLogo: {
    "& img": {
      width: '100%',
    }
  },

  theAboutBtn: {
    background: "#EA4843",
    padding: "0.75rem 0",
    borderRadius: "0.5rem",
    width: "45%"
  },

  theSendFlex: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },

  theSendIcon: {
    background: "#EA4843",
    padding: "0.7rem"
  },

  thePaddingOne: {
    padding: "2rem",

    ...md("md", {
      padding: "5rem 3rem",

    }),
  },

  thePaddingTwo: {
    padding: "2rem",
    ...md("md", {
      padding: "5rem 0 5rem 3rem",
    }),
  },


  thePaddingThree: {
    padding: "2rem",
    ...md("md", {
      padding: "5rem 0 5rem 2rem",
    }),
  }



};

export default makeStyles(config);