import React from "react";
import { Container, Grid } from "@mui/material";
import useStyles from "./styles";
import loginmobilelogo from "../../assets/images/loginmobilelogo.png"
import Paragraph from "../Typography/Paragraph";
import footerplane from "../../assets/images/footerplane.svg"
import FormInput from "../../components/FormInput/FormInput";
import footercall from "../../assets/images/footercall.svg"
import footerlocation from "../../assets/images/footerlocation.svg"
import footermail from "../../assets/images/footermail.svg"


const Footer = () => {
  const classes = useStyles();
  const isMobile = window.innerWidth <= 768;


  return (
    <React.Fragment>
      <div className={classes.theFooterBanner}>
        <Container>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingOne}>
                <div className={classes.theFooterLogo}>
                  <img src={loginmobilelogo} />
                </div>

                <Paragraph color="#FFFFFF" size="14px" bold="400" margin="0.75rem 0" style={{ lineHeight: "20px" }}>
                 Financing businesses since 2010
                </Paragraph>

                <div className={classes.theAboutBtn}>
                  <Paragraph center color="#FFFFFF" size="14px" bold="500" >
                    About Us
                  </Paragraph>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              <div className={classes.thePaddingTwo}>
                <Paragraph color="#FFFFFF" size="18px" bold="bold" >
                  Newsletter
                </Paragraph>

                <Paragraph color="#FFFFFF" size="14px" bold="400" margin="0.5rem 0" style={{ lineHeight: "20px" }}>
                  
                </Paragraph>

                <div className={classes.theSendFlex}>

                  <div style={{ width: "100%" }}>
                    <FormInput
                      bold="700"
                      placeholder="Your mail address"
                      margin="unset"
                      bg="#FFFFFF"
                    />
                  </div>

                  <div className={classes.theSendIcon}>
                    <img src={footerplane} />
                  </div>

                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.thePaddingThree}>
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img src={footercall} />
                  </div>
                  <Paragraph color="#ffffff" size="14px" bold="500" margin="0 0 0 0.5rem">
                    +6 012 34567890
                  </Paragraph>
                </div>

                <br /> */}

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img src={footermail} />
                  </div>
                  <Paragraph color="#ffffff" size="14px" bold="500" margin="0 0 0 0.5rem">
                    hform@email.com
                  </Paragraph>
                </div>

                {/* <br />

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <img src={footerlocation} />
                  </div>
                  <Paragraph color="#ffffff" size="14px" bold="500" margin="0 0 0 0.5rem">
                    2, Jalan Timur 2/3,
                    Taman Perniagaan
                    81000 Johor Bahru,
                    Johor, Malaysia
                  </Paragraph>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div style={{ padding: "0.5rem 0" }}>
        <Paragraph center color="#282828" size="18px" bold="400" >
          copyright © 2022   Privacy Policy
        </Paragraph>
      </div>
    </React.Fragment>
  )

};

export default Footer;
